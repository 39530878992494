import { createStore } from "vuex";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  updateProfile,
} from "firebase/auth";
import { auth } from "@/main";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import { toast } from "vue3-toastify";
import i18n, { selectedLocale } from "@/i18n";
import urls from "@/config/urls";

export default createStore({
  state: {
    loggedIn: false,
    user: null,
    locale: selectedLocale,
    theme: "light",
    nextUrl: null,
  },
  getters: {
    isLogged: (state) => {
      return state.loggedIn;
    },
    isAdmin: (state) => {
      return state.user && state.user.permissions ? state.user.permissions.includes("admin") : false;
    },
    isRoot: (state) => {
      return state.user && state.user.permissions ? state.user.permissions.includes("root") : false;
    },
    user: (state) => {
      return state.user;
    },
    displayName: (state) => {
      return state.user.lastName + " " + state.user.firstName;
    },
    locale: (state) => {
      return state.locale;
    },
    theme: (state) => {
      return state.theme;
    },
    nextUrl: (state) => {
      return state.nextUrl;
    },
  },
  mutations: {
    setLoggedIn(state, newState) {
      state.loggedIn = newState;
    },
    setUser(state, data) {
      state.user = data;
    },
    setPhotoUrl(state, newPhotoUrl) {
      state.user.photoUrl = newPhotoUrl;
    },
    setUserPermissions(state, newPermissions) {
      state.user.permissions = newPermissions;
    },
    setLocale(state, newLocale) {
      state.locale = newLocale;
    },
    setTheme(state, newTheme) {
      state.theme = newTheme;
    },
    setNextUrl(state, nextUrl) {
      state.nextUrl = nextUrl;
    },
  },
  actions: {
    changeLogInStatus({ commit }, logInStatus) {
      commit("setLoggedIn", logInStatus);
    },
    changeUserPermissions({ commit }, newPermissions) {
      commit("setUserPermissions", newPermissions);
    },
    changeLocale({ commit }, newLocale) {
      i18n.global.locale.value = newLocale;
      commit("setLocale", newLocale);
    },
    changeTheme({ commit }, newTheme) {
      commit("setTheme", newTheme);
      document.documentElement.setAttribute("data-theme", newTheme);
    },
    changeNextUrl({ commit }, newNextUrl) {
      commit("setNextUrl", newNextUrl);
    },
    // Methods

    /* ---------------------------------------------------- FIREBASE ---------------------------------------------------- */
    async register({ dispatch }, { email, password, name }) {
      try {
        const response = await createUserWithEmailAndPassword(auth, email, password);
        await updateProfile(response.user, { displayName: name });
        const user = {
          uid: response.user.uid,
          email: response.user.email,
          displayName: name,
        };
        // Log the user object to make sure it's correct
        console.log("User object to be saved:", user);

        // Call saveUserToMySQL and handle the response
        let responseApi = await dispatch("saveUserToMySQL", user);
        console.log("Response from saveUserToMySQL:", responseApi);

        if (responseApi && responseApi.success) {
          await dispatch("setUser", user);
          await dispatch("setLoggedIn", true);
        } else {
          dispatch("toast", {
            type: "error",
            message: i18n.global.t("register.toast.error"),
            duration: 3000,
          });
        }
      } catch (error) {
        console.error("Registration error:", error);
        dispatch("toast", {
          type: "error",
          message: error.message,
          duration: 3000,
        });
        throw error;
      }
    },
    async login({ commit, dispatch }, { email, password }) {
      const response = await signInWithEmailAndPassword(auth, email, password);
      await dispatch("fetchUserFromMySQL", response.user.uid);
      commit("setLoggedIn", true);
    },
    /* ---------------------------------------------------- DATABASE ---------------------------------------------------- */
    async checkPermission({ state }, permission) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${urls.api}/users/${state.user.uid}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };
      try {
        const response = await axios.request(config);
        const userPermissions = response.data.user.permissions;
        // console.log(userPermissions);
        // console.log(userPermissions.includes(permission));
        return userPermissions.includes(permission); // Retourner la valeur directement
      } catch (error) {
        console.error("Error checking permission", error);
        return false;
      }
    },
    async fetchUserFromMySQL({ commit }, uid) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${urls.api}/users/${uid}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };
      axios
        .request(config)
        .then((response) => {
          const userData = {
            uid: response.data.user.uid,
            firstName: response.data.user.firstName,
            lastName: response.data.user.lastName,
            email: response.data.user.email,
            photoUrl: response.data.user.photoUrl || null,
            phoneNumber: response.data.user.phoneNumber || null,
            permissions: response.data.user.permissions || [],
          };
          commit("setUser", userData);
        })
        .catch((error) => {
          console.error("Error fetching user from MySQL", error);
        });
    },
    async saveUserToMySQL({ commit }, user) {
      console.log("SAVE USER TO MYSQL", user);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${urls.api}/save-user`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        data: {
          uid: user.uid,
          email: user.email,
          firstName: user.displayName.split(" ")[0] || "",
          lastName: user.displayName.split(" ")[1] || "",
          photoUrl: null,
          phoneNumber: null,
          permissions: ["user"],
        },
      };
      return axios
        .request(config)
        .then((response) => {
          console.log("Successfully saved user to MySQL:", response.data);
          return response.data;
        })
        .catch((error) => {
          console.error("Error saving user to MySQL:", error);
          return { success: false, error: error };
        });
    },
    async updateUser({ commit }, { uid, userData }) {
      try {
        console.log("UPDATE USER", userData);
        // Configuration de la requête
        const config = {
          method: "put",
          url: `${urls.api}/users/${uid}`,
          data: userData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
          },
        };

        // Exécution de la requête avec axios
        const response = await axios.request(config);

        // Si la requête réussit, mise à jour du state Vuex
        commit("setUser", response.data.user);
      } catch (error) {
        console.error("Failed to update user:", error);
        throw error; // Relance l'erreur pour gestion ultérieure si nécessaire
      }
    },
    async logout({ commit, dispatch }) {
      try {
        await signOut(auth);
        commit("setUser", null);
        commit("setLoggedIn", false);
        dispatch("toast", {
          type: "success",
          message: i18n.global.t("general.toast.logout"),
          duration: 3000,
        });
      } catch (error) {
        dispatch("toast", {
          type: "errorAC",
          message: error.message,
          duration: 3000,
        });
      }
    },
    async resetPassword({ dispatch }, email) {
      await sendPasswordResetEmail(auth, email);
      dispatch("toast", {
        type: "success",
        message: i18n.global.t("login.toast.passwordReset"),
        duration: 3000,
      });
    },
    toast(context, { type, message, duration = 3000, handler = () => {} }) {
      toast(message, {
        theme: "colored",
        type: type,
        position: "bottom-right",
        autoClose: duration,
        transition: "slide",
        dangerouslyHTMLString: true,
        onClose: () => handler(),
      });
    },
  },
  plugins: [createPersistedState()],
});
