<template>
  <div :class="['dropdown', dropdownClass]">
    <div tabindex="0" role="button" class="btn m-1 bg-base-300">
      Theme
      <svg
        width="12px"
        height="12px"
        class="h-2 w-2 fill-current opacity-60 inline-block"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2048 2048"
      >
        <path d="M1799 349l242 241-1017 1017L7 590l242-241 775 775 775-775z"></path>
      </svg>
    </div>
    <ul
      tabindex="0"
      class="dropdown-content z-50 bg-base-200 text-base-content rounded-box top-px h-[28.6rem] max-h-[calc(100vh-10rem)] w-56 overflow-y-auto border border-white/5 shadow-2xl outline outline-1 outline-black/5 mt-16 skeleton"
    >
      <div>
        <div class="grid grid-cols-1 gap-3 p-3">
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('light')">
            <span
              data-theme="light"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'light'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">light</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('dark')">
            <span
              data-theme="dark"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'dark'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">dark</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button
            class="outline-base-content text-start outline-offset-4"
            @click="changeTheme('cupcake')"
            data-set-theme="cupcake"
          >
            <span
              data-theme="cupcake"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'cupcake'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">cupcake</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('bumblebee')">
            <span
              data-theme="bumblebee"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'bumblebee'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">bumblebee</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('emerald')">
            <span
              data-theme="emerald"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'emerald'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">emerald</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('corporate')">
            <span
              data-theme="corporate"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'corporate'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">corporate</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('synthwave')">
            <span
              data-theme="synthwave"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'synthwave'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">synthwave</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('retro')">
            <span
              data-theme="retro"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'retro'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">retro</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('cyberpunk')">
            <span
              data-theme="cyberpunk"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'cyberpunk'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">cyberpunk</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('valentine')">
            <span
              data-theme="valentine"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'valentine'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">valentine</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('halloween')">
            <span
              data-theme="halloween"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'halloween'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">halloween</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('garden')">
            <span
              data-theme="garden"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'garden'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">garden</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('forest')">
            <span
              data-theme="forest"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'forest'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">forest</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('aqua')">
            <span
              data-theme="aqua"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'aqua'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">aqua</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('lofi')">
            <span
              data-theme="lofi"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'lofi'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">lofi</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('pastel')">
            <span
              data-theme="pastel"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'pastel'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">pastel</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('fantasy')">
            <span
              data-theme="fantasy"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'fantasy'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">fantasy</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('wireframe')">
            <span
              data-theme="wireframe"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'wireframe'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">wireframe</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('black')">
            <span
              data-theme="black"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'black'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">black</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('luxury')">
            <span
              data-theme="luxury"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'luxury'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">luxury</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('dracula')">
            <span
              data-theme="dracula"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'dracula'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">dracula</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('cmyk')">
            <span
              data-theme="cmyk"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'cmyk'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">cmyk</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('autumn')">
            <span
              data-theme="autumn"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'autumn'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">autumn</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('business')">
            <span
              data-theme="business"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'business'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">business</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('acid')">
            <span
              data-theme="acid"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'acid'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">acid</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('lemonade')">
            <span
              data-theme="lemonade"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'lemonade'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">lemonade</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('night')">
            <span
              data-theme="night"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'night'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">night</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('coffee')">
            <span
              data-theme="coffee"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'coffee'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">coffee</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('winter')">
            <span
              data-theme="winter"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'winter'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">winter</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('dim')">
            <span
              data-theme="dim"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'dim'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">dim</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('nord')">
            <span
              data-theme="nord"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'nord'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">nord</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <button class="outline-base-content text-start outline-offset-4" @click="changeTheme('sunset')">
            <span
              data-theme="sunset"
              class="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans"
            >
              <span class="grid grid-cols-5 grid-rows-3">
                <span class="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                  <svg
                    v-if="currentTheme === 'sunset'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-3 w-3 shrink-0"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                  </svg>
                  <span class="flex-grow text-sm">sunset</span>
                  <span class="flex h-full shrink-0 flex-wrap gap-1">
                    <span class="bg-primary rounded-badge w-2"></span>
                    <span class="bg-secondary rounded-badge w-2"></span>
                    <span class="bg-accent rounded-badge w-2"></span>
                    <span class="bg-neutral rounded-badge w-2"></span>
                  </span>
                </span>
              </span>
            </span>
          </button>
        </div>
      </div>
    </ul>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { computed } from "vue";

const props = defineProps({
  dropdownClass: {
    type: String,
    default: '',
  }
});

const store = useStore();
const currentTheme = computed(() => store.state.theme);

const changeTheme = (theme) => {
  let selectedTheme;
  if (!theme) {
    selectedTheme = store.state.theme === "light" ? "dark" : "light";
  } else {
    selectedTheme = theme;
  }
  store.dispatch("changeTheme", selectedTheme);
  // console.log(currentTheme.value);
  // console.log(currentTheme.value === "light");
};
</script>
