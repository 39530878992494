<template>
  <div class="drawer">
    <!-- DRAWER TOGGLE -->
    <input id="my-drawer" type="checkbox" class="drawer-toggle" />
    <div class="drawer-content">
      <div class="navbar bg-base-200 flex justify-between items-center px-4 py-2">
        <label for="my-drawer" class="btn btn-ghost btn-circle">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" />
          </svg>
        </label>
        <div class="mx-2 flex-1 px-2 text-xl font-bold">{{ $t("website.title") }}</div>
      </div>
    </div>
    <div class="drawer-side z-50">
      <label for="my-drawer" class="drawer-overlay"></label>
      <div class="menu bg-base-200 text-base-content min-h-full w-80 p-4 space-y-4">
        <li class="content-end">
          <label for="my-drawer" class="btn btn-ghost btn-circle">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </label>
        </li>
        <li>
          <button @click="goToPage('home')">{{ $t("navbar.home") }}</button>
        </li>
        <li>
          <button @click="goToPage('about')">{{ $t("navbar.about") }}</button>
        </li>
        <li>
          <button @click="goToPage('contact')">{{ $t("navbar.contact") }}</button>
        </li>
        <li>
          <button @click="goToPage('hero')">{{ $t("navbar.quit") }}</button>
        </li>
        <div class="border-t border-b">
          <li v-if="isAuthenticated">
            <button @click="goToPage('administration')">{{ $t("navbar.admin") }}</button>
            <button @click="goToPage('profile')">{{ $t("navbar.profile") }}</button>
            <button @click="logout">{{ $t("navbar.logout") }}</button>
          </li>
          <li v-else>
            <button @click="goToPage('login')">{{ $t("navbar.login") }}</button>
            <button @click="goToPage('register')">{{ $t("navbar.register") }}</button>
          </li>
        </div>
        <div>
          <!-- LANGUAGE -->
          <li>
            <div class="dropdown">
              <div tabindex="0" role="button" class="btn m-1 bg-base-300">
                <div class="w-10 rounded-full flex justify-center">
                  <svg
                    class="swap-on fill-current w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 122.88 92.91"
                  >
                    <title>language</title>
                    <path
                      d="M20.15,83.63,31.63,73.4a2.89,2.89,0,0,1,1.91-.73h27.8a.92.92,0,0,0,.93-.93V65.9H68v5.84a6.71,6.71,0,0,1-6.68,6.68H34.62L19.3,92.07a2.87,2.87,0,0,1-4.9-2V78.42H6.69A6.71,6.71,0,0,1,0,71.74V28.59a6.76,6.76,0,0,1,6.69-6.68H43.35v5.75H6.69a1,1,0,0,0-.94.93V71.74a.91.91,0,0,0,.28.65,1,1,0,0,0,.66.28H17.27a2.88,2.88,0,0,1,2.88,2.88v8.08Zm.21-19.48L29.6,36.24h8.83l9.24,27.91H40.35L38.8,59.07H29.15l-1.51,5.08ZM30.79,53.24h6.37L34,41.81,30.79,53.24ZM76.63,13.35h8.7V11.11a.69.69,0,0,1,.69-.69h4.65a.68.68,0,0,1,.68.69v2.24h9.76a.68.68,0,0,1,.68.69V18.5a.68.68,0,0,1-.68.68H99.56a26.3,26.3,0,0,1-.91,3.88l0,.06a26.07,26.07,0,0,1-1.74,4.15,32.34,32.34,0,0,1-2.14,3.43c-.67,1-1.41,1.9-2.2,2.83a35.78,35.78,0,0,0,3.68,3.83,41.43,41.43,0,0,0,5.09,3.74.68.68,0,0,1,.21.94l-2.39,3.73a.69.69,0,0,1-1,.2,45.88,45.88,0,0,1-5.58-4.08l0,0a41.42,41.42,0,0,1-4-4.1C87.3,38.93,86.15,40,85,41l0,0c-1.36,1.12-2.79,2.2-4.47,3.36a.69.69,0,0,1-1-.17L77,40.53a.69.69,0,0,1,.17-1c1.66-1.14,3-2.19,4.36-3.28,1.16-1,2.28-2,3.49-3.16a44.82,44.82,0,0,1-2.77-4.45A28.84,28.84,0,0,1,80,22.9a.68.68,0,0,1,.47-.84l4.27-1.19a.68.68,0,0,1,.84.47A22.62,22.62,0,0,0,89,28.7L90.27,27a26.33,26.33,0,0,0,1.51-2.47l0,0A19.43,19.43,0,0,0,93,21.62a24,24,0,0,0,.66-2.44h-17a.69.69,0,0,1-.69-.68V14a.69.69,0,0,1,.69-.69Zm27,56.82L88.26,56.51H61.54a6.73,6.73,0,0,1-6.69-6.68V6.69a6.71,6.71,0,0,1,2-4.72l.2-.18A6.67,6.67,0,0,1,61.54,0h54.65a6.69,6.69,0,0,1,4.71,2l.19.2a6.69,6.69,0,0,1,1.79,4.51V49.83a6.73,6.73,0,0,1-6.69,6.68h-7.7V68.13a2.88,2.88,0,0,1-4.91,2ZM91.26,51.49l11.47,10.23V53.64a2.88,2.88,0,0,1,2.88-2.88h10.58a.92.92,0,0,0,.65-.28.91.91,0,0,0,.29-.65V6.69a1,1,0,0,0-.22-.58L116.84,6a1,1,0,0,0-.65-.29H61.54A.94.94,0,0,0,61,6L60.89,6a.92.92,0,0,0-.28.65V49.83a.92.92,0,0,0,.93.93H89.35a2.86,2.86,0,0,1,1.91.73Z"
                    />
                  </svg>
                </div>
                <svg
                  width="12px"
                  height="12px"
                  class="h-2 w-2 fill-current opacity-60 inline-block"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 2048 2048"
                >
                  <path d="M1799 349l242 241-1017 1017L7 590l242-241 775 775 775-775z"></path>
                </svg>
              </div>
              <ul
                tabindex="0"
                class="menu menu-sm dropdown-content skeleton top-px mt-16 z-[1] p-2 shadow rounded-box w-52"
              >
                <div>
                  <li @click="changeLocale('fr')">
                    <div>
                      <svg
                        v-if="locale === 'fr'"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="h-3 w-3 shrink-0"
                      >
                        <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                      </svg>
                      <div>{{ $t("navbar.language.fr") }}</div>
                    </div>
                  </li>
                  <li @click="changeLocale('en')">
                    <div>
                      <svg
                        v-if="locale === 'en'"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="h-3 w-3 shrink-0"
                      >
                        <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                      </svg>
                      <div>{{ $t("navbar.language.en") }}</div>
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </li>
          <!-- THEME -->
          <li>
            <ThemeDropdown />
          </li>
        </div>
      </div>

      <!-- TITLE_IN_DRAWER -->
      <div class="my-drawer-3 text-xl font-bold">
        <button class="btn btn-ghost text-xl" @click="goToPage('home')">{{ $t("website.title") }}</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ThemeDropdown from "./ThemeDropdown.vue";

const router = useRouter();
const store = useStore();

const isAuthenticated = computed(() => store.getters.isLogged);
const isAdmin = computed(() => store.getters.isAdmin);
const user = computed(() => store.getters.user);
const locale = computed(() => store.state.locale);

const logout = async () => {
  await store.dispatch("logout");
  router.push({ name: "Hero" });
};

const changeLocale = (locale) => {
  store.dispatch("changeLocale", locale);
  const path = router.currentRoute.value.fullPath;
  const newPath = path.replace(/^\/[a-z]{2}/, `/${locale}`);
  router.push(newPath);
};

const goToPage = (page) => {
  document.getElementById("my-drawer").checked = false;
  router.push(`/${store.state.locale}/` + page);
};
</script>

<style>
.drawer-side {
  z-index: 1000;
}
</style>
