import { createApp } from "vue";
import App from "./App.vue";

import "./index.css";
import i18n from "@/i18n";
import store from "./store";

const app = createApp(App);

// ROUTER
import router from "./router";
app.use(router);
app.use(store);

// WINDOW SIZE
import { VueWindowSizePlugin } from "vue-window-size/option-api";
app.use(VueWindowSizePlugin);

// INTERNATIONALIZATION
app.use(i18n);

// FIREBASE
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
const firebaseConfig = require("@/config/firebase");
const firebaseapp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseapp);
onAuthStateChanged(auth, async (user) => {
  if (user) {
    const token = await user.getIdToken();
    localStorage.setItem("authToken", token);
  } else {
    store.commit("setUser", null);
    store.commit("setLoggedIn", false);
    localStorage.removeItem("authToken");
  }
});

// TOASTIFY
import "vue3-toastify/dist/index.css";

// MOUNT APP
app.mount("#app");

// FUNCTIONS
app.config.globalProperties.goToPage = function (page, newTab) {
  if (newTab) {
    let routeData = this.$router.resolve(`/${store.state.locale}/` + page);
    window.open(routeData.href, "_blank");
  } else {
    this.$router.push(`/${store.state.locale}/` + page);
  }
};

app.config.globalProperties.scrollToTop = function () {
  window.scrollTo(0, 0);
};

export { auth };
