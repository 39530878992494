export default {
  "profile": {
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations personnelles"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
    "data": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pas de email"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pas de nom"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pas de téléphone"])}
    },
    "updateButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])},
    "deleteAccountButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le compte"])},
    "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
    "toast": {
      "noUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur trouvé"])},
      "updateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour réussie"])},
      "photoUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo mise à jour"])}
    }
  },
  "website": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I.C.A.R.IE"])}
  },
  "hero": {
    "modal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue chez I.C.A.R.IE"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes le BDE (Bureau des Étudiants) de l'École d'Ingénieurs Jules Vernes."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer"])}
    },
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visiter"])}
  },
  "navbar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "language": {
      "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
      "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espagnol"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allemand"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italien"])},
      "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugais"])},
      "nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Néerlandais"])},
      "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russe"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinois"])},
      "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japonais"])},
      "ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabe"])}
    },
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon profil"])},
    "quit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])}
  },
  "searchbar": {
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher..."])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous à votre compte."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "placeholder": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre email"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre mot de passe"])}
    },
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
    "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de compte ? S'inscrire ici."])},
    "toast": {
      "error": {
        "invalidCredential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email ou mot de passe incorrect."])},
        "missingEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer votre email."])},
        "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet email n'est pas enregistré."])}
      },
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes connecté."])},
      "passwordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un email de réinitialisation de mot de passe a été envoyé."])}
    }
  },
  "register": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez un compte."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "placeholder": {
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre prénom"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre nom de famille"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre email"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre mot de passe"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez votre mot de passe"])}
    },
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déjà un compte ? Se connecter ici."])},
    "toast": {
      "error": {
        "passwordMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas."])},
        "emailInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet email est déjà utilisé."])},
        "weakPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit contenir au moins 6 caractères."])}
      },
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte a été créé avec succès."])}
    }
  },
  "contact": {
    "HereMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email de contact :"])},
    "icarie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["icarie"])},
    "mailAdress1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["u-picardie.fr"])},
    "mailAdress2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eijv.eu"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue sur notre site"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes le BDE (Bureau des Étudiants) de l'École d'Ingénieurs Jules Vernes."])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos services"])},
    "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publié le: "])},
    "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par: "])}
  },
  "about": {
    "moment": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assemblée Générale portant à la création du BDE"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14 Décembre 2023"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création officiel de l'Association"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["08 Avril 2024"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Première édition de la journée EIJV #ONE FAMILY"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23 Mai 2024"])}
      }
    ],
    "person": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venceslas Gbaguidi"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trésorier"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jean-Sebastien Ruschmann"])}
      }
    ],
    "board": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bureau actuel :"])},
    "old_board": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anciens bureaux :"])},
    "old_boardArray": [
      {
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2024"])},
        "person": [
          {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venceslas Gbaguidi"])}
          },
          {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trésorier"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jean-Sebastien Ruschmann"])}
          }
        ]
      }
    ],
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos projet :"])},
    "projectArray": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création du site web du BDE"])},
        "person": [
          {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead Developer"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicolas Auvray"])}
          },
          {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UX Developer"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Léo Seguin"])}
          },
          {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rédacteur"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melvin Fore"])}
          },
          {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphiste"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nathanel Nakache--Malbete"])}
          }
        ]
      }
    ],
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre histoire"])}
  },
  "contacter": {
    "lien": {
      "youtube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "general.toast.logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion réussie"])}
}