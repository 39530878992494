<template>
  <div class="navbar bg-base-200">
    <div class="navbar-start">
      <!-- DROPDOWN -->
      <div class="dropdown dropdown-start">
        <div tabindex="0" class="btn btn-ghost btn-circle">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" />
          </svg>
        </div>
        <ul tabindex="0" class="menu menu-sm dropdown-content skeleton mt-3 p-2 rounded-box w-52 z-50">
          <li>
            <button @click="goToPage('home')">{{ $t("navbar.home") }}</button>
          </li>
          <li>
            <button @click="goToPage('about')">{{ $t("navbar.about") }}</button>
          </li>
          <li>
            <button @click="goToPage('contact')">{{ $t("navbar.contact") }}</button>
          </li>
          <li>
            <button @click="goToPage('hero')">{{ $t("navbar.quit") }}</button>
          </li>
        </ul>
      </div>
    </div>

    <!-- MIDDLE -->
    <div class="navbar-center">
      <button class="btn btn-ghost text-xl" button @click="goToPage('home')">{{ $t("website.title") }}</button>
    </div>

    <!-- END -->
    <div class="navbar-end">
      <!-- LANGUAGE -->
      <div class="dropdown dropdown-end">
        <div tabindex="0" role="button" class="btn m-1 bg-base-300">
          <div class="w-10 rounded-full flex justify-center">
            <svg class="swap-on fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 92.91">
              <title>language</title>
              <path
                d="M20.15,83.63,31.63,73.4a2.89,2.89,0,0,1,1.91-.73h27.8a.92.92,0,0,0,.93-.93V65.9H68v5.84a6.71,6.71,0,0,1-6.68,6.68H34.62L19.3,92.07a2.87,2.87,0,0,1-4.9-2V78.42H6.69A6.71,6.71,0,0,1,0,71.74V28.59a6.76,6.76,0,0,1,6.69-6.68H43.35v5.75H6.69a1,1,0,0,0-.94.93V71.74a.91.91,0,0,0,.28.65,1,1,0,0,0,.66.28H17.27a2.88,2.88,0,0,1,2.88,2.88v8.08Zm.21-19.48L29.6,36.24h8.83l9.24,27.91H40.35L38.8,59.07H29.15l-1.51,5.08ZM30.79,53.24h6.37L34,41.81,30.79,53.24ZM76.63,13.35h8.7V11.11a.69.69,0,0,1,.69-.69h4.65a.68.68,0,0,1,.68.69v2.24h9.76a.68.68,0,0,1,.68.69V18.5a.68.68,0,0,1-.68.68H99.56a26.3,26.3,0,0,1-.91,3.88l0,.06a26.07,26.07,0,0,1-1.74,4.15,32.34,32.34,0,0,1-2.14,3.43c-.67,1-1.41,1.9-2.2,2.83a35.78,35.78,0,0,0,3.68,3.83,41.43,41.43,0,0,0,5.09,3.74.68.68,0,0,1,.21.94l-2.39,3.73a.69.69,0,0,1-1,.2,45.88,45.88,0,0,1-5.58-4.08l0,0a41.42,41.42,0,0,1-4-4.1C87.3,38.93,86.15,40,85,41l0,0c-1.36,1.12-2.79,2.2-4.47,3.36a.69.69,0,0,1-1-.17L77,40.53a.69.69,0,0,1,.17-1c1.66-1.14,3-2.19,4.36-3.28,1.16-1,2.28-2,3.49-3.16a44.82,44.82,0,0,1-2.77-4.45A28.84,28.84,0,0,1,80,22.9a.68.68,0,0,1,.47-.84l4.27-1.19a.68.68,0,0,1,.84.47A22.62,22.62,0,0,0,89,28.7L90.27,27a26.33,26.33,0,0,0,1.51-2.47l0,0A19.43,19.43,0,0,0,93,21.62a24,24,0,0,0,.66-2.44h-17a.69.69,0,0,1-.69-.68V14a.69.69,0,0,1,.69-.69Zm27,56.82L88.26,56.51H61.54a6.73,6.73,0,0,1-6.69-6.68V6.69a6.71,6.71,0,0,1,2-4.72l.2-.18A6.67,6.67,0,0,1,61.54,0h54.65a6.69,6.69,0,0,1,4.71,2l.19.2a6.69,6.69,0,0,1,1.79,4.51V49.83a6.73,6.73,0,0,1-6.69,6.68h-7.7V68.13a2.88,2.88,0,0,1-4.91,2ZM91.26,51.49l11.47,10.23V53.64a2.88,2.88,0,0,1,2.88-2.88h10.58a.92.92,0,0,0,.65-.28.91.91,0,0,0,.29-.65V6.69a1,1,0,0,0-.22-.58L116.84,6a1,1,0,0,0-.65-.29H61.54A.94.94,0,0,0,61,6L60.89,6a.92.92,0,0,0-.28.65V49.83a.92.92,0,0,0,.93.93H89.35a2.86,2.86,0,0,1,1.91.73Z"
              />
            </svg>
          </div>
          <svg
            width="12px"
            height="12px"
            class="h-2 w-2 fill-current opacity-60 inline-block"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2048 2048"
          >
            <path d="M1799 349l242 241-1017 1017L7 590l242-241 775 775 775-775z"></path>
          </svg>
        </div>
        <ul tabindex="0" class="menu menu-sm dropdown-content skeleton top-px mt-16 z-[1] p-2 shadow rounded-box w-52">
          <div>
            <li @click="changeLocale('fr')">
              <div>
                <svg
                  v-if="locale === 'fr'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="h-3 w-3 shrink-0"
                >
                  <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                </svg>
                <div>{{ $t("navbar.language.fr") }}</div>
              </div>
            </li>
            <li @click="changeLocale('en')">
              <div>
                <svg
                  v-if="locale === 'en'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="h-3 w-3 shrink-0"
                >
                  <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
                </svg>
                <div>{{ $t("navbar.language.en") }}</div>
              </div>
            </li>
          </div>
        </ul>
      </div>
      <!-- THEME -->
      <ThemeDropdown :dropdownClass="'dropdown-end'" />

      <!-- SEARCH BAR -->
      <button class="btn btn-ghost btn-circle" @click="toggleSearchBar">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </button>
      <!-- CONNEXION -->
      <div class="dropdown dropdown-end">
        <div tabindex="0" role="button" class="btn btn-ghost btn-circle">
          <div class="w-10 rounded-full flex justify-center">
            <div v-if="isAuthenticated">
              <div v-if="user && user.photoUrl">
                <img :src="profileImageUrl" @error="handleError" alt="Profile Image" class="rounded-full w-10 h-10" />
              </div>
              <div v-else>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 32 32"
                  class="inline-block w-5 h-5 stroke-current"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M25.838 31H6.162a3.957 3.957 0 0 1-3.245-1.661 3.956 3.956 0 0 1-.549-3.604l.704-2.113a6.034 6.034 0 0 1 4.966-4.059C10.131 19.307 13.211 19 16 19c2.788 0 5.869.307 7.963.563a6.032 6.032 0 0 1 4.965 4.059l.704 2.113a3.954 3.954 0 0 1-.55 3.604A3.955 3.955 0 0 1 25.838 31zM16 21c-2.688 0-5.681.298-7.718.549a4.02 4.02 0 0 0-3.312 2.706l-.704 2.112c-.206.618-.106 1.274.274 1.802S5.511 29 6.162 29h19.676a1.98 1.98 0 0 0 1.622-.83c.381-.528.48-1.185.275-1.803l-.704-2.112a4.02 4.02 0 0 0-3.312-2.706C21.681 21.298 18.687 21 16 21zM16 18c-4.687 0-8.5-3.813-8.5-8.5S11.313 1 16 1c4.687 0 8.5 3.813 8.5 8.5S20.687 18 16 18zm0-15c-3.584 0-6.5 2.916-6.5 6.5S12.416 16 16 16s6.5-2.916 6.5-6.5S19.584 3 16 3z"
                  />
                </svg>
              </div>
            </div>
            <div v-else>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                class="inline-block w-5 h-5 stroke-current"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0 a1 1 0 11-2 0 1 1 0 012 0z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <ul tabindex="0" class="menu menu-sm dropdown-content skeleton mt-3 z-[1] p-2 shadow rounded-box w-52">
          <div v-if="isAuthenticated">
            <li v-if="isAdmin">
              <button class="justify-between" @click="goToPage('administration')">
                {{ $t("navbar.admin") }} <span v-if="news" class="badge">New</span>
              </button>
            </li>
            <li>
              <button @click="goToPage('profile')">{{ $t("navbar.profile") }}</button>
            </li>
            <li>
              <button @click="logout">{{ $t("navbar.logout") }}</button>
            </li>
          </div>
          <div v-else>
            <li>
              <button @click="goToPage('login')">{{ $t("navbar.login") }}</button>
            </li>
            <li>
              <button @click="goToPage('register')">{{ $t("navbar.register") }}</button>
            </li>
          </div>
        </ul>
      </div>
    </div>
  </div>
  <div v-if="isSearchBarVisible">
    <SearchBar @search="handleSearch" />
  </div>
</template>

<script setup>
import { computed, ref, defineEmits, watchEffect } from "vue";
import { useStore } from "vuex";
import SearchBar from "./SearchBar.vue";
import ThemeDropdown from "./ThemeDropdown.vue";
import { useRouter } from "vue-router";
import request from "./javascript/request";

const emit = defineEmits(["search"]);
const router = useRouter();
const store = useStore();
const isAuthenticated = computed(() => store.getters.isLogged);
const isAdmin = computed(() => store.getters.isAdmin);
const user = computed(() => store.getters.user);
const profileImageUrl = ref("");

watchEffect(async () => {
  if (user.value && user.value.photoUrl) {
    try {
      profileImageUrl.value = await request.getProfileImage(user.value.photoUrl);
    } catch (error) {
      profileImageUrl.value = "/public/defaultUser.svg";
    }
  } else {
    profileImageUrl.value = "/public/defaultUser.svg";
  }
});

const handleError = () => {
  profileImageUrl.value = "/public/defaultUser.svg";
};

const locale = computed(() => store.state.locale);
const isSearchBarVisible = ref(false);
const news = false;
const logout = async () => {
  await store.dispatch("logout");
  router.push("Hero");
};

const changeLocale = (locale) => {
  store.dispatch("changeLocale", locale);
  const path = router.currentRoute.value.fullPath;
  const newPath = path.replace(/^\/[a-z]{2}/, `/${locale}`);
  router.push(newPath);
};

const toggleSearchBar = () => {
  isSearchBarVisible.value = !isSearchBarVisible.value;
};

const handleSearch = (searchQuery) => {
  emit("search", searchQuery);
};
</script>
