export default {
  "profile": {
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal informations"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "data": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No email"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No name"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No phone"])}
    },
    "updateButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "deleteAccountButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "toast": {
      "noUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No user found"])},
      "updateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["update success"])},
      "photoUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["photo updated"])}
    }
  },
  "website": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I.C.A.R.IE"])}
  },
  "hero": {
    "modal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to I.C.A.R.IE"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are the Students' Union of the École d'Ingénieurs Jules Vernes."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter"])}
    },
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["take a look"])}
  },
  "navbar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "language": {
      "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
      "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
      "nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])},
      "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
      "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
      "ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])}
    },
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My profile"])},
    "quit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quit"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  },
  "searchbar": {
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to your account."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "placeholder": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email address"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password"])}
    },
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password ?"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No account ? Register here"])},
    "toast": {
      "error": {
        "invalidCredential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email or password."])},
        "missingEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email address."])},
        "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User not found. Please register first."])}
      },
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are now connected."])},
      "passwordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A password reset link has been sent to your email address."])}
    }
  },
  "register": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "placeholder": {
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your firstname"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your lastname"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email address"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your password"])}
    },
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account ? Login here"])},
    "toast": {
      "error": {
        "passwordMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match."])},
        "emailInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email is already in use. Please use another one."])},
        "weakPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 6 characters long."])}
      },
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account created successfully. You can now login."])}
    }
  },
  "contact": {
    "HereMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email of contact :"])},
    "icarie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["icarie"])},
    "mailAdress1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["u-picardie.fr"])},
    "mailAdress2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eijv.eu"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to our website"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are the Students' Union of the École d'Ingénieurs Jules Vernes."])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our services Learn more"])},
    "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published the: "])},
    "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By: "])}
  },
  "about": {
    "moment": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Meeting to create the Students' Union"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14th of December 2023"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Official creation of the Union"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8th of April 2024"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First edition of the EIJV #ONE FAMILY day"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23rd of May 2024"])}
      }
    ],
    "person": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chairman"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venceslas Gbaguidi"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treasurer"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jean-Sebastien Ruschmann"])}
      }
    ],
    "board": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Board :"])},
    "old_board": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formers Boards :"])},
    "old_boardArray": [
      {
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2024"])},
        "person": [
          {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chairman"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venceslas Gbaguidi"])}
          },
          {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treasurer"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jean-Sebastien Ruschmann"])}
          }
        ]
      }
    ],
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our projects :"])},
    "projectArray": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation of the Students' Union website"])},
        "person": [
          {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead Developer"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicolas Auvray"])}
          },
          {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UX Developer"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Léo Seguin"])}
          },
          {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melvin Fore"])}
          },
          {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphic designer"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nathanel Nakache--Malbete"])}
          }
        ]
      }
    ],
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our story"])}
  },
  "contacter": {
    "lien": {
      "youtube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "general.toast.logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logout succeded"])}
}