<template>
  <component v-if="showNavbar" :is="currentNavbar" @search="onSearch" />
  <router-view />
</template>

<script setup>
import { ref, computed, watchEffect } from "vue";
import { useRoute } from "vue-router";
import AppNavbar from "@/components/AppNavbar.vue";
import AppNavbarMobile from "@/components/AppNavbarMobile.vue";

const route = useRoute();
const showNavbar = computed(() => route.name != "Hero");

const currentNavbar = ref(AppNavbar);

const onSearch = (searchQuery) => {
  console.log(searchQuery);
};

const updateNavbar = () => {
  if (window.innerWidth < 1024) {
    currentNavbar.value = AppNavbarMobile;
  } else {
    currentNavbar.value = AppNavbar;
  }
};

watchEffect(() => {
  updateNavbar();
  window.addEventListener("resize", updateNavbar);
  return () => {
    window.removeEventListener("resize", updateNavbar);
  };
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
