const axios = require("axios");
const urls = require("@/config/urls");

let request = {};

request.getProfileImage = async (photoUrl) => {
  if (!photoUrl) return "/public/defaultUser.svg";
  const imageUrl = urls.api + photoUrl;
  try {
    const response = await axios.get(imageUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to load profile image", error);
    return "/public/defaultUser.svg";
  }
};

export default request;
