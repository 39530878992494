import { createRouter, createWebHistory } from "vue-router";
import { languages } from "@/i18n";
import store from "@/store";

const childRoutes = [
  {
    path: "hero",
    name: "Hero",
    component: () => import(/* webpackChunkName: "Hero" */ "../views/HeroView.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "home",
    name: "Home",
    component: () => import(/* webpackChunkName: "Home" */ "../views/HomeView.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "about",
    name: "About",
    component: () => import(/* webpackChunkName: "About" */ "../views/AboutView.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "administration",
    name: "Administration",
    component: () => import(/* webpackChunkName: "Administration" */ "../views/AdministrationView.vue"),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "profile",
    name: "Profile",
    component: () => import(/* webpackChunkName: "Profile" */ "../views/ProfileView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "login",
    name: "Login",
    component: () => import(/* webpackChunkName: "Login" */ "../views/LoginView.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "register",
    name: "Register",
    component: () => import(/* webpackChunkName: "Register" */ "../views/RegisterView.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "contact",
    name: "Contact",
    component: () => import(/* webpackChunkName: "Contact" */ "../views/ContactView.vue"),
    meta: {
      requiresAuth: false,
    },
  },
];

const childRoutePaths = childRoutes.map((route) => route.path);

const routes = [
  {
    path: "/",
    name: "root",
  },
  {
    path: "/:locale?",
    beforeEnter(to, from) {
      let locale = to.params.locale;

      if (languages.includes(locale)) {
        // IF VALID LOCALE
        if (store.state.locale !== locale) {
          store.dispatch("changeLocale", locale);
        }
        return true;
      }

      // FOR INVALID LOCALE FALLBACK TO STORE LOCALE
      return {
        name: to.name,
        params: { ...to.params, locale: store.state.locale },
      };
    },
    children: childRoutes,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Error",
    beforeEnter(to, from) {
      if (childRoutePaths.includes(to.fullPath.split("/")[1])) {
        return { path: store.state.locale + to.fullPath };
      }
      return true;
    },
    component: () => import(/* webpackChunkName: "error" */ "../views/Error.vue"),
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash, behavior: "smooth" };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0, behavior: "smooth" };
    }
  },
});

router.beforeEach(async (to, from) => {
  // setup theme
  let theme = store.state.theme;
  if (theme) {
    document.documentElement.setAttribute("data-theme", theme);
  }

  let loggedIn = store.getters.isLogged;
  store.dispatch("changeLogInStatus", loggedIn);

  // CHECKS ROUTES THAT REQUIRE AUTH
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!loggedIn) {
      return { name: "Login" };
    } else {
      if (to.matched.some((record) => record.meta.requiresAdmin)) {
        const isAdmin = await store.dispatch("checkPermission", "admin");
        // console.log("ADMIN : ", isAdmin);
        if (!isAdmin) {
          return { name: "Error" }; // ou une autre page d'erreur
        }
      }
      return true;
    }
  }

  // ROUTES THAT DON'T REQUIRE AUTH
  else {
    // LOGIN PAGE
    if (to.name == "Login") {
      // NOT LOGGED IN
      if (!loggedIn) {
        return true;
      }

      // LOGGED IN => Home PAGE
      else {
        return { name: "Home" };
      }
    } else {
      // NO LOGIN REQUIRED + NOT GOING TO LOGIN PAGE
      if (to.name == "root") {
        return { name: "Hero", params: { locale: store.state.locale } };
      } else {
        return true;
      }
    }
  }
});

export default router;
