<template>
  <div class="flex justify-center mb-5 mt-2">
    <input
      type="text"
      v-model="search"
      :placeholder="$t('searchbar.placeholder')"
      class="input input-bordered w-2/3 mr-2"
      @keyup.enter="emitSearchEvent"
    />
    <button class="btn btn-outline" @click="emitSearchEvent">Rechercher</button>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  data() {
    return {
      search: "",
    };
  },
  methods: {
    emitSearchEvent() {
      this.$emit("search", this.search); // Émet un événement 'search' avec la valeur actuelle de la recherche
    },
  },
};
</script>
